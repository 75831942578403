import React from "react"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"

//Image

import pdfLogo from "../images/delinea-image-conversational-cyber-insurance-ebook-thumbnail.jpg"
import ConversationalCyberContact from "../components/ConversationalCyberContact"

const ConversationalCyberInsurance = () => {
  return (
    <Layout>
      <SEO
        title="Conversational Geek: Cyber Insurance"
        description="Conversational Geek’s free guide to cyber insurance breaks down the choices and the process for obtaining cyber insurance."
        keywords={["", "", ""]}
        lang="en-gb"
      />
      {/*********** Contact *************/}
      <div
        className="contact-section pdf-ebook-page"
        id="contact"
        // style={{ paddingTop: "120px" }}
      >
        <div className="outer-container" style={{ padding: "0" }}>
          {/* <h2>Contact Us</h2> */}
          <div className="pdf-hero maturity-model">
            <div className="blur-wrap">
              <ScrollAnimation animateIn="animate__fadeInUp" duration="0.75">
                <div style={{ display: "flex" }} className="pdf-hero-wrap">
                  <div
                    style={{
                      display: "flex",
                      // background: "red",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pdfLogo}
                      alt="delinea image tool cyber insurance checklist thumbnail"
                      style={{ width: "70%" }}
                    ></img>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "left",
                        color: "#FFFFFF",
                        marginBottom: "0em",
                      }}
                    >
                      CONVERSATIONAL GEEK:
                      <br />
                      Cyber Insurance
                    </h1>
                    <p
                      style={{
                        textAlign: "left",
                        color: "white",
                        fontSize: "x-large",
                      }}
                    >
                      How Cybersecurity and Cyber Insurance are Intertwined
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row">
            <div
              className="inner-container-2"
              style={{
                justifyContent: "flex-start",
                marginTop: "28px",
                paddingLeft: "80px",
              }}
            >
              <p style={{ textAlign: "left" }}>
                Cyber insurance isn’t a legal term, nor even a standard
                insurance term. There are hundreds, if not thousands, of cyber
                insurance policies from insurance companies worldwide. Each one
                tries to provide a unique offering to gain an advantage over the
                competition, which can make identifying the right policy for you
                a challenging task.
              </p>
              <p style={{ textAlign: "left" }}>
                Conversational Geek’s quick guide to cyber insurance breaks down
                the choices and the process for obtaining cyber insurance. It
                includes expert guidance from cyber insurance brokers, using
                plain language helpful for folks new to the insurance industry.
              </p>
              <h3
                style={{
                  textAlign: "left",
                  textTransform: "none",
                  width: "100%",
                }}
              >
                Inside, you'll learn:
              </h3>
              <ul
                style={{
                  width: "100%",
                  paddingBottom: "30px",
                  textAlign: "left",
                }}
                className="pdf-list"
              >
                <li>
                  How to prepare before applying for cyber insurance or renewing
                  your policy.
                </li>
                <li>
                  What cybersecurity technologies and processes you'll need to
                  obtain or renew cyber insurance.
                </li>
                <li>
                  How to read a cyber insurance policy quote to understand
                  coverage categories and key information such as limits and
                  exclusions.
                </li>
                <li>
                  Questions insurers are sure to ask that influence your rates.
                </li>
              </ul>
              <p style={{ textAlign: "left", width: "100%" }}>
                Register to get your copy of Conversational Geek’s guide.
              </p>
              {/* <i style={{ fontSize: "x-small", textAlign: "left" }}>
                *Items will be sent once a meeting is accepted, attended and you
                have provided a suitable shipping address. Please allow suitable
                time for shipping
              </i> */}
            </div>
            <div
              className="inner-container-2"
              style={{
                paddingRight: "80px",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  textTransform: "none",
                  width: "100%",
                  paddingLeft: "1.25em",
                  marginTop: "28px",
                  paddingBottom: "0",
                }}
                id="checklist-prompt"
              >
                Get the checklist now.
              </h3>
              <ConversationalCyberContact />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ConversationalCyberInsurance
